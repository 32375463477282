<template>
  <div class="row">
    <div class="col-12">
      <c-table
        ref="table"
        title="평가대상 목록 - 도면/노드"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="process.drawNodes"
        :merge="grid.merge"
        :columnSetting="false"
        :expandAll="false"
        :isFullScreen="false"
        :usePaging="false"
        :hideBottom="true"
        :filtering="false"
        :editable="editable&&!disabled&&isSelect"
        selection="multiple"
        rowKey="ramAssessNodeId"
        @innerBtnClicked="innerBtnClicked"
      >
        <!-- @rowClick="rowClick" -->
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-chip 
            outline square clickable 
            size="md"
            color="light-green-8" 
            text-color="white"
            icon="contact_support"
            :label="$language('범례')"
            style="height:26px;margin-right:2px !important;"
            v-show="editable&&!disabled&&isSelect&&process.drawNodes&&process.drawNodes.length>0"
            class="q-ml-sm">
            <q-popup-proxy>
              <q-banner>
                <q-btn color="green" icon="add" label="" size="7px"></q-btn>
                : {{$language('노드추가')}}
              </q-banner>
            </q-popup-proxy>
          </q-chip>
          <q-btn-group outline >
            <!-- <c-btn v-show="editable&&!disabled&&isSelect" label="추가" icon="add" @btnClicked="addDraw" /> -->
            <c-btn v-show="editable&&!disabled&&isSelect" label="추가" icon="add" @btnClicked="addProcessDraw" />
            <c-btn v-show="editable&&!disabled&&isSelect&&process.drawNodes&&process.drawNodes.length>0" label="삭제" icon="remove" @btnClicked="removeDrawNode" />
            <c-btn 
              v-show="false" 
              :isSubmit="isSave"
              :url="saveUrl"
              :param="drawNodeSave"
              mappingType="PUT"
              label="저장" 
              icon="save"
              @beforeAction="saveDrawNode"
              @btnCallback="saveDrawNodeCallback" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='customCol'">
            <q-chip
              color="amber"
              outline square
              :clickable="true"
              text-color="white"
              style="margin-bottom:4px !important;"
              @click.stop="() => { rowClick(props.row, props.pageIndex) }">
              {{editable&&!disabled&&isSelect ? $language('수정') : $language('상세')}}
            </q-chip>
          </template>
        </template>
      </c-table>
    </div>
    <q-dialog v-model="dialog" persistent>
      <q-card style="min-width: 350px">
        <q-form ref="editForm">
          <c-card title="도면/노드 추가" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn 
                  v-show="editable&&!disabled&&isSelect&&isRowEditing" 
                  :disabled="disabledNode" 
                  label="저장" 
                  icon="save" 
                  @btnClicked="accepts"/>
                <c-btn 
                  label='닫기' 
                  icon="close" 
                  @btnClicked="closeDialog"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-12">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="공정"
                  name="processName"
                  v-model="drawNode.processName">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="도면번호"
                  name="diagramNo"
                  v-model="drawNode.diagramNo">
                </c-text>
                <!-- <c-text
                  :editable="editable"
                  :disabled="true"
                  :afterIcon="!disabledNode&&isRowEditing ? [
                    { name: 'search', click: true, callbackName: 'searchSop', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeSop', color: 'red' }
                  ] : null"
                  label="도면번호"
                  name="diagramNo"
                  v-model="drawNode.diagramNo"
                  @searchSop="searchDraw"
                  @removeSop="removeDraw">
                </c-text> -->
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="도면명"
                  name="diagramTitle"
                  v-model="drawNode.diagramTitle">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable"
                  :disabled="disabledNode||!isRowEditing"
                  :required="true"
                  label="노드번호"
                  name="nodeNo"
                  v-model="drawNode.nodeNo"
                  @dataChange="dataChange">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable"
                  :disabled="disabledNode||!isRowEditing"
                  :required="true"
                  label="노드"
                  name="nodeTitle"
                  v-model="drawNode.nodeTitle"
                  @dataChange="dataChange">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable"
                  :disabled="disabledNode||!isRowEditing"
                  :required="true"
                  label="노드설명"
                  name="nodeDesc"
                  v-model="drawNode.nodeDesc"
                  @dataChange="dataChange">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable"
                  :disabled="disabledNode||!isRowEditing"
                  :required="true"
                  label="설계조건"
                  name="designConditions"
                  v-model="drawNode.designConditions"
                  @dataChange="dataChange">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable"
                  :disabled="disabledNode||!isRowEditing"
                  :required="true"
                  label="작동조건"
                  name="operatingConditions"
                  v-model="drawNode.operatingConditions"
                  @dataChange="dataChange">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable"
                  :disabled="disabledNode||!isRowEditing"
                  :required="true"
                  label="설계의도"
                  name="designIntent"
                  v-model="drawNode.designIntent"
                  @dataChange="dataChange">
                </c-text>
              </div>
              <div class="col-12">
                <c-datepicker
                  :editable="editable"
                  :disabled="disabledNode||!isRowEditing"
                  :required="true"
                  :range="true"
                  label="검토기간"
                  name="studyPeriod"
                  v-model="drawNode.studyPeriod"
                  @dataChange="dataChange"
                />
              </div>
            </template>
          </c-card>
        </q-form>
      </q-card>
    </q-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'hazop-target-process',

  props: {
    process: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        drawNodes: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    updateBtnData: {
      type: Object,
      default: () => ({
        flag: false,
        research: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'processCd' },
          { index: 1, colName: 'groupPsiDiagramId' },
        ],
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'center',
            style: 'width:30%',
            sortable: false,
          },
          {
            name: 'diagramTitle',
            field: 'diagramTitle',
            description: 'diagramNo',
            label: '도면명',
            align: 'center',
            style: 'width:35%',
            sortable: false,
            innerBtn: true,
            btns: [
              { label: '', icon: 'add', color: 'green' }
            ]
          },
          {
            name: 'nodeTitle',
            field: 'nodeTitle',
            description: 'nodeNo',
            label: '노드',
            align: 'center',
            style: 'width:35%',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '수정',
            align: 'center',
            style: 'width:40px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
        height: '460px'
      },
      drawNode: {
        ramAssessNodeId: '',  // 노드 일련번호
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        ramTechniqueCd: '',  // 평가기법 코드_HAZOP, K-PSR 구분(평가계획의 평가기법 코드 그대로 사용)
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        groupPsiDiagramId: '',  // 도면 일련번호
        psiDiagramId: '',  // 도면 일련번호
        diagramNo: '',  // 도면번호
        diagramTitle: '',  // 도면명
        nodeNo: '',  // 노드 번호_수기입력
        nodeTitle: '',  // 노드 타이틀
        nodeDesc: '',  // 노드 설명
        designConditions: '',  // 설계조건
        operatingConditions: '',  // 작동조건
        designIntent: '',  // 설계의도
        startStudyDate: '',  // 검토 시작일
        endStudyDate: '',  // 검토 종료일
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        studyPeriod: [], // 검토기간
      },
      drawNodeSave: {
        ramRiskAssessmentPlanId: '',
        processCd: '',
        draws: [],
        nodes: [],
        deleteNodes: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      isSameNodeName: false,
      isRowClick: false,
      isRowAdd: false,
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      rowIndex: 0,
      dialog: false,
    };
  },
  computed: {
    isSelect() {
      return this.process 
        && this.process.processCd
        && this.process.ramProcessAssessStepCd !== 'RPA0000005' ? true : false
    },
    disabledNode() {
      return this.disabled || !this.isSelect
    },
    isRowEditing() {
      return this.isRowClick || this.isRowAdd
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.drawNode.list.url
      this.saveUrl = transactionConfig.ram.drawNode.save.url
      this.deleteUrl = transactionConfig.ram.drawNode.delete.url
      // code setting
      // list setting
    },
    getDrawNodes() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,
        processCd: this.process.processCd
      }
      this.$http.request((_result) => {
        this.process.drawNodes = _result.data
      },);
    },
    addProcessDraw() {
      this.popupOptions.title = '공정 도면 검색'; 
      this.popupOptions.param = {
        type: 'multiple', // multiple
        processCd: this.process.processCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/psi/diagramPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeSopPopup;
    },
    closeSopPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.process.drawNodes, { psiDiagramId: item.psiDiagramId }) === -1) {
            this.process.drawNodes.splice(0, 0, {
              ramAssessNodeId: uid(),
              // 도면정보
              ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,
              processCd: this.process.processCd,
              processName: this.process.processName,
              ramTechniqueCd: 'RT00000001',
              groupPsiDiagramId: this.process.processCd + '/' + item.psiDiagramId,
              psiDiagramId: item.psiDiagramId,
              diagramNo: item.diagramNo,
              diagramTitle: item.diagramTitle,
              // 노드정보
              nodeNo: '',  // 노드 번호_수기입력
              nodeTitle: '',  // 노드 타이틀
              nodeDesc: '',  // 노드 설명
              designConditions: '',  // 설계조건
              operatingConditions: '',  // 작동조건
              designIntent: '',  // 설계의도
              startStudyDate: '',  // 검토 시작일
              endStudyDate: '',  // 검토 종료일
              studyPeriod: [], // 검토기간
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            })
          }
        })
        this.saveDrawNode();
      }
    },
    // searchDraw() {
    //   this.popupOptions.title = '공정 도면 검색'; // 공정 도면 검색
    //   this.popupOptions.param = {
    //     type: 'single',
    //     processCd: this.process.processCd
    //   };
    //   this.popupOptions.target = () => import(`${'@/pages/common/psi/diagramPop.vue'}`);
    //   this.popupOptions.width = '70%';
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closeDrawPopup;
    // },
    // closeDrawPopup(data) {
    //   this.popupOptions.target = null;
    //   this.popupOptions.visible = false;
    //   if (data && data.length > 0) {
    //     this.$set(this.drawNode, 'groupPsiDiagramId', (this.process.processCd + '/' + data[0].psiDiagramId))
    //     this.$set(this.drawNode, 'psiDiagramId', data[0].psiDiagramId)
    //     this.$set(this.drawNode, 'diagramNo', data[0].diagramNo)
    //     this.$set(this.drawNode, 'diagramTitle', data[0].diagramTitle)
    //   }
    // },
    // removeDraw() {
    //   this.$set(this.drawNode, 'groupPsiDiagramId', '')
    //   this.$set(this.drawNode, 'psiDiagramId', '')
    //   this.$set(this.drawNode, 'diagramNo', '')
    //   this.$set(this.drawNode, 'diagramTitle', '')
    // },
    rowClick(row, index) {
      /**
       * 이후에 해당 정보를 클릭 한 후 수정하려고 할 수 있도록 열것임
       * 다만 해당 마다 존재하는 키값이 temp로 따진 키가 아닐 경우에만 한에서 열어줄것임
       * 도면seq, 노드seq
       */
      this.rowIndex = index;
      this.isRowClick = true;
      this.$_.extend(this.drawNode, row);
      if (this.drawNode.editFlag !== 'C') {
        this.drawNode.editFlag = 'U'
        this.drawNode.chgUserId = this.$store.getters.user.userId
        this.drawNode.studyPeriod = [this.drawNode.startStudyDate, this.drawNode.endStudyDate]
      }
      this.dialog = true;
    },
    // addDraw() {
    //   this.rowIndex = 0;
    //   // this.disabled1 = false;
    //   // this.disabled2 = false;
    //   // this.disabled3 = false;
    //   this.isRowAdd = true;
    //   let drawNode = { 
    //     ramAssessNodeId: uid(),
    //     // 도면정보
    //     ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,
    //     processCd: this.process.processCd,  // 단위공정 코드
    //     processName: this.process.processName,
    //     ramTechniqueCd: 'RT00000001',
    //     groupPsiDiagramId: '', // props.row.processCd + '/' + props.row.psiDiagramId,
    //     psiDiagramId: '', // props.row.psiDiagramId,
    //     diagramNo: '',
    //     diagramTitle: '',
    //     // 노드정보
    //     nodeNo: '',  // 노드 번호_수기입력
    //     nodeTitle: '',  // 노드 타이틀
    //     nodeDesc: '',  // 노드 설명
    //     designConditions: '',  // 설계조건
    //     operatingConditions: '',  // 작동조건
    //     designIntent: '',  // 설계의도
    //     startStudyDate: '',  // 검토 시작일
    //     endStudyDate: '',  // 검토 종료일
    //     studyPeriod: [], // 검토기간
    //     regUserId: this.$store.getters.user.userId,
    //     editFlag: 'C',
    //   }
    //   this.rowClick(drawNode, this.rowIndex, true)
    // },
    innerBtnClicked(col, props) {
      this.rowIndex = props.rowIndex;
      this.isRowAdd = true;
      if (col.name === 'diagramTitle') {
        this.addNode(props)
      }
    },
    addNode(props) {
      /**
       * 2022-01-24 KDH
       * 버튼 누를 경우 목록에 개행하도록 변경
       */
      let drawNode = { 
        ramAssessNodeId: uid(),
        // 도면정보
        ramRiskAssessmentPlanId: props.row.ramRiskAssessmentPlanId,
        processCd: props.row.processCd,
        processName: props.row.processName,
        ramTechniqueCd: props.row.ramTechniqueCd,
        groupPsiDiagramId: props.row.processCd + '/' + props.row.psiDiagramId,
        psiDiagramId: props.row.psiDiagramId,
        diagramNo: props.row.diagramNo,
        diagramTitle: props.row.diagramTitle,
        // 노드정보
        nodeNo: '',  // 노드 번호_수기입력
        nodeTitle: '',  // 노드 타이틀
        nodeDesc: '',  // 노드 설명
        designConditions: '',  // 설계조건
        operatingConditions: '',  // 작동조건
        designIntent: '',  // 설계의도
        startStudyDate: '',  // 검토 시작일
        endStudyDate: '',  // 검토 종료일
        studyPeriod: [], // 검토기간
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      }
      // this.process.drawNodes.splice(this.rowIndex, 0, this.$_.clone(drawNode));
      this.rowClick(drawNode, this.rowIndex)
    },
    dataChange() {
      if (this.isRowAdd) {
        if (this.$_.findIndex(this.process.drawNodes, { 
          groupPsiDiagramId: this.drawNode.groupPsiDiagramId,
          nodeTitle: this.drawNode.nodeTitle, 
        }) > -1) {
          this.isSameNodeName = true;
        } else {
          this.isSameNodeName = false;
        }
      } else {
        if (this.$_.findIndex(this.$_.filter(this.process.drawNodes, {
          groupPsiDiagramId: this.drawNode.groupPsiDiagramId
        }), { nodeTitle: this.drawNode.nodeTitle, }) > -1) {
          this.isSameNodeName = true;
        } else {
          this.isSameNodeName = false;
        }
      }
    },
    accepts() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.isSameNodeName) {
            window.getApp.$emit('ALERT', {
              title: '안내',
              message: '동일한 노드명이 존재합니다.',
              type: 'warning', // success / info / warning / error
            });
            this.isSameNodeName = false;
            return;
          }

          let isProgress = true;
          if (!this.process.drawNodes || this.process.drawNodes.length === 0) {
            isProgress = false
            window.getApp.$emit('ALERT', {
              title: '안내',
              message: '데이터가 없습니다.',
              type: 'warning', // success / info / warning / error
            });
          } else {
            // this.$_.forEach(this.process.drawNodes, drawNode => {
            //   if (!drawNode.nodeNo) {
            //     isProgress = false
            //     window.getApp.$emit('ALERT', {
            //       title: '안내',
            //       message: '노드정보가 입력되지 않은 도면이 있습니다.',
            //       type: 'warning', // success / info / warning / error
            //     });
            //     return false;
            //   }
            // });

            if (isProgress) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  if (this.isRowAdd) {
                    this.process.drawNodes.splice(this.rowIndex, 0, this.$_.clone(this.drawNode));
                  } else {
                    this.$_.extend(this.process.drawNodes[this.rowIndex], this.$_.clone(this.drawNode))
                  }

                  this.isRowClick = false;
                  this.isRowAdd = false;
                  Object.assign(this.$data.drawNode, this.$options.data().drawNode);
                  this.dialog = false;
                  this.saveDrawNode();
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            }
          }

        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    closeDialog() {
      this.isSameNodeName = false;
      this.isRowClick = false;
      this.isRowAdd = false;
      this.dialog = false;
    },
    saveDrawNode() {
      this.drawNodeSave.ramRiskAssessmentPlanId = this.process.ramRiskAssessmentPlanId
      this.drawNodeSave.processCd = this.process.processCd
      this.drawNodeSave.draws = [];
      this.drawNodeSave.nodes = [];
      this.$_.forEach(this.process.drawNodes, drawNode => {
        if (this.$_.findIndex(this.drawNodeSave.draws, { psiDiagramId: drawNode.psiDiagramId }) === -1) {
          this.drawNodeSave.draws.push(drawNode)
        }
        // 저장된 데이터이며 수정하지 않은 경우 period 속성이 없음
        if (drawNode.studyPeriod && drawNode.studyPeriod.length > 0) {
          drawNode.startStudyDate = drawNode.studyPeriod[0]
          drawNode.endStudyDate = drawNode.studyPeriod[1]
        }
      });
      this.drawNodeSave.nodes = this.process.drawNodes;

      this.isSave = !this.isSave
    },
    saveDrawNodeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveProcess', this.process.processCd)
      this.getDrawNodes();
      /**
       * 계획정보수정
       *  변경사항을 적용하기 위함
       */
      if (this.updateBtnData.flag) {
        this.updateBtnData.research = uid();
      }
    },
    removeDrawNode() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let message = '삭제하시겠습니까?';
        if (this.updateBtnData.flag) {
          message = '현재 평가실행 중인 노드입니다.\n\r관련되어 있는 정보 및 개선사항, 위험등록부가 같이 삭제됩니다.\n\r진행하시겠습니까?'
        }
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: message,
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
          
              this.$_.forEach(selectData, item => {
                this.process.drawNodes = this.$_.reject(this.process.drawNodes, item);
              })

              this.$refs['table'].$refs['compo-table'].clearSelection();

              /**
               * 계획정보수정
               *  변경사항을 적용하기 위함
               */
              if (this.updateBtnData.flag) {
                this.updateBtnData.research = uid();
              }
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
